import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`*`}{`All members and their families are invited to watch the air show
and Thunder at The Ville today!  We’ll have a potluck, play some games,
and hang out.  We’ll start around 2:00pm but we’ll be open all day until
Thunder is over.  Hope you can make it!  We’ll have our normal class
times today as well.`}</em></strong></p>
    <p>{`Push Press 4×4\\@85% 1RM`}</p>
    <p>{`Barbell Rows 4×4\\@85%`}</p>
    <p>{`then,`}</p>
    <p>{`200M Run`}</p>
    <p>{`20 Push Jerks (115/75)`}</p>
    <p>{`200M Run`}</p>
    <p>{`15 Push Jerks (135/95)`}</p>
    <p>{`200M Run`}</p>
    <p>{`10 Push Jerks (155/105)`}</p>
    <p>{`200M Run`}</p>
    <p>{`5 Push Jerks (175/115)`}</p>
    <p>{`200M Run`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our next Mobility class will be tomorrow at 12:00, just before our
12:30 class.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      